import { ComponentProps, FC } from 'react'
import styles from 'styles/components/FullscreenMessage.module.sass'
import Button from './Button'
import Icon from './Icon'
import classNames from 'classnames'
import Markdown from './Markdown'
import GradientBorderContainer from './Welcome/GradientBorderContainer'

type FullscreenMessageProps = {
    icon: Extract<ComponentProps<typeof Icon>['name'], `${string}-gradient`>
    message: string
    description: string
    buttonText?: string
    onClick?: () => void
    href?: string
    dark?: boolean
    noCircle?: boolean
}

const FullscreenMessage: FC<FullscreenMessageProps> = ({
    icon,
    buttonText,
    onClick,
    message,
    description,
    dark,
    noCircle,
    href
}) => {
    const IconComponent = noCircle ? (
        <Icon
            className={classNames(styles.icon, styles.largeBottomMargin)}
            name={icon}
        />
    ) : (
        <GradientBorderContainer
            type={dark ? 'dark' : 'light'}
            className={styles.circle}
        >
            <Icon className={styles.icon} name={icon} />
        </GradientBorderContainer>
    )

    return (
        <div className={styles.container}>
            {IconComponent}
            <h2
                className={classNames(styles.message, {
                    [styles.lightText ?? '']: dark
                })}
            >
                <Markdown>{message}</Markdown>
            </h2>
            <p
                className={classNames(styles.description, {
                    [styles.lightText ?? '']: dark
                })}
            >
                <Markdown>{description}</Markdown>
            </p>

            {buttonText && (
                <Button
                    small
                    type={dark ? 'primary' : 'secondary'}
                    onClick={onClick}
                    href={href}
                >
                    {buttonText}
                </Button>
            )}
        </div>
    )
}

export default FullscreenMessage
