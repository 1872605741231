import { NextPage } from 'next/types'
import FullscreenMessage from '../components/FullscreenMessage'
import { useRouter } from 'next/router'
import { useEffect } from 'react'
import styles from 'styles/pages/404.module.sass'

// TODO: pull message text from database
const NotFound: NextPage = ({}) => {
    const router = useRouter()

    useEffect(() => void router.prefetch('/'))

    return (
        <div className={styles.container}>
            <FullscreenMessage
                dark
                icon="emoji-surprised-gradient"
                message="Ой! Эта сссылка потерялась."
                description="Но мы не хотим потерять вас."
                buttonText="Возвращайтесь!"
                href="/"
            />
        </div>
    )
}

export default NotFound
